import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import * as s from './styles/globalStyles'
import styled from 'styled-components'

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input

export const StyledButton = styled.button`
    padding: 10px;
    border-radius: 50px;
    border: none;
    background-color: var(--secondary);
    padding: 10px;
    font-weight: bold;
    color: var(--secondary-text);
    width: auto;
    cursor: pointer;
    box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`

export const StyledRoundButton = styled.button`
    padding: 10px;
    border-radius: 100%;
    border: none;
    background-color: var(--primary);
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    color: var(--primary-text);
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`

export const ResponsiveWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretched;
    align-items: stretched;
    width: 100%;
    @media (min-width: 767px) {
        flex-direction: row;
    }
`

export const StyledLogo = styled.img`
    width: 200px;
    border-radius: 50%;
    @media (min-width: 767px) {
        width: 300px;
    }
    transition: width 0.5s;
    transition: height 0.5s;
`

export const StyledImg = styled.img`
    box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
    border: 4px dashed var(--secondary);
    background-color: var(--accent);
    border-radius: 100%;
    width: 200px;
    @media (min-width: 900px) {
        width: 250px;
    }
    @media (min-width: 1000px) {
        width: 300px;
    }
    transition: width 0.5s;
`

export const StyledLink = styled.a`
    color: var(--secondary);
    text-decoration: none;
`
const whitelisted_acc = [
    '0x1355918ba35c5ce697447af88d72f4a6b016c530',
    '0x3f284422269c3386da7978d53df510b1b3b4ac0a',
    '0x9fe808d8a9e2e341ea30a8d5ac1e301c32b779fc',
    '0x9a02f69dc0d9d1670fb18283a5d739829360026a',
    '0x88e01261a69fbaa35102918a479f6ff3db4f1438',
    '0x0756a4ce2d56bf9bcfed2808d5b2482d5279045a',
    '0xddc0817d9022de94a5289d3c5c7a423adba31cd0',
    '0xae99439c57be527089579a24b1cdbcc1fe354569',
    '0xaca77c166befe048958069f61bce0a0e9ed77071',
    '0x2999ff0c39f07169357813d3ebb45c007a2d1a99',
    '0x3558f9b6a653ba2c4d1e2a45454382a64d6c107d',
    '0xed857184c73be87aa4261e93b5437c0d55d5e5bf',
    '0x3e8667aad144845b184e161ef1654c481dbccc6b',
    '0x05e342862bdc94d93d75bf0423d0ace8864b8d40',
    '0x6e8e5ef69ed663622f8291835f9a0562aac1ef1c',
    '0x6def13e4d65214c51f36977a4f817a2507524a08',
    '0xfe173b71666d54d0341c9dac720c52ea2ccb33ca',
    '0x8f174a30d712ee391d531dd03330e851db11ecde',
    '0x0553602039435db42d20305ed2a31ff5bcb73e6d',
    '0xb7a68dd54936cd26aeac7ce10f24220766120d14',
    '0x247883783b30575fc329691d82c41af7402c38b4',
    '0x14cff5f9cfb1db9e7287bdb1faeffc25e24fc46c',
    '0xe304580965611ca029cfd87983fbae7b6a2013a8',
    '0x8e795fbf30df6a919a7bbc767c9f0b437072a064',
    '0x54ac5ed05575a0970a2619db883e7531565508be',
    '0xc93fc60c23042293295a261c01d7ed0b30ed5746',
    '0x08181f7c7fc7b06a5f88f4a400f00dc4b3f1cea8',
    '0x1b9957e2918100f6c486de91050c106751fc4e67',
    '0x75b7e4e41b0036770b786b575715fe93137491c3',
    '0x28d67c8dcb1bb1664dea1009943d088eb6f68b3f',
    '0xd0223375d85931840a4a6e22ba57e554028a3706',
    '0x96c7745e2d1138ab18887550ed1586acc900ce03',
    '0xdfacb7cc1e9206a59878daffafdace1766190d32',
    '0x3c523dbf1a6e6f15fbfbb672dbde8084629f8975',
    '0xa61b95bc1f8427b4c2f3c9b973ba3f30f88fc09b',
    '0x6594ed69cc1e08f4d1d691c67a0b30958576a5bd',
    '0x6e626b332dc410627e4d04335345811d56d0e02c',
    '0xd9d0b9e34e8680a6598a719b507694eea79d702c',
    '0xe7de2ff245bcf840d0d20c04c00fec2779861f36',
    '0x7d09a4e3fbc577a165c004b08a17fb45e111dcb5',
    '0xafc58693ef764f9cfb612d5e2c6db5beef67ab95',
    '0x7f4866f4f5e97d879366b9ccf71391fba6878dc8',
    '0xce6576365262b4df6b3ce4aa18e9c2a634d41d4f',
    '0xce7ff14e966c31df699a784033045f709d5997d9',
    '0xec94f4b478da740b3a7f359a28e5065bd2e13a25',
    '0x41c8ae946affb79a71e8d9c99861f329d5d819bc',
    '0xe978924fab6fa5775414d4a5b96c9bb601e86572',
    '0x696670f665f1ea3489f0c57cf58b911a258c75f1',
    '0x5df06c68d5e304d99014ed55b356bf84c139a85e',
    '0x0370b56e3d98260295afde17cd518aa9b2a16747',
    '0xf41f4c7ec02b1021a4f3936c293dde0a34849f7d',
    '0x5fd8a539c5cb098f6e923946b0015ae746eaaf49',
    '0x9d2c8b0830bdbb850b977d39317a4ebf4fa51f30',
    '0x77550eb7f4fb8ec32c7da31eb43db71a80b9a5c5',
    '0x62a79cfa1451ed03a27de83e194c8cb23f9b1d5c',
    '0xa2116f0fe3320f1cd52d2fd04eb600dfda5c484e',
    '0x1902439d7aa3c5374b9e766a9510d5fe833696a1',
    '0x35b3e25fdc3f5d9b54aa5af35b05338dee6d652a',
    '0xe14b37de50a042f8b246dc41e0cdb345b8bf8c4a',
    '0xb41a359c8801abe5fc94ead50a4c1fa4544885f4',
    '0x9e43d6ba519a2111c6b97f0d56208586264e6a75',
    '0xee9affa3db30adbf7db8030e2deddd3dee6b0be5',
    '0x318fd082c6cd270438f10885ce6f02d53b87e049',
    '0x6f85f16b35a6bcde77ff085634875ca608c826b4',
    '0x8449a07a3d8d6b029b289dc3dc27f7cf43eccec2',
    '0xc4afe4030e65d3f10ae20dafe822e206153156b5',
    '0xbeaa16abd3a04b05f7e171eea89114efbda62f6e',
    '0x9ea0c4bb61096ade0638e52bf0241dea5c2424ad',
    '0xe8a30a34ba93de71fcf77e2c4bb228e982976f42',
    '0x2f669b9ce84a4e19fba88af7c135fa13c45a26fa',
    '0x0979667dba025217d24187b959a186f05d4acc37',
    '0x1ce2b79c37bb9815dc36e77b57d2f7407c43eff8',
    '0xea253aba919582bc86c6a9ff2276d2b38a896e71',
    '0xf7026d059f48ca14949388c4010157022e9075c5',
    '0x06f667cd96735f1850799baa0917800e7d0beb39',
    '0xbf4a60736dd30b807e176e97ae2fd551d0764eb2',
    '0x44f6cf1a330b584f6f021cae08c98e59107bb4a5',
    '0x73624873cd686a4217c265554c57c817a1265368',
    '0x2560a3b55d9b043e9aeee6998ec7e222a078a383',
    '0xdcce6db92d0a024c060a0cb3439cc8863d20e6b3',
    '0x205b993bb19930c80fb10ddf4f4c423e49c4caac',
    '0x58a6aac81eaf19dca1297b021af7f871e2122ab4',
    '0x30f662d7b32be999da54d82027c171261bc50e9d',
    '0x5d824ac4b69776f5f3c5961043d684ff6091faa4',
    '0xccc7f2edf954454c238a421fa896c26d09b7d078',
    '0x727a10ae1afc91754d357b4748f6d793c9795026',
    '0xe1717d2ee514ad1933b4760ebf7551e19f5b9ff8',
    '0x97db0e57b1c315a08cc889ed405adb100d7f137d',
    '0x3c6730546829d451fa62dc214693002b4747f162',
    '0x7453395417538bb459870402a800acf88d293df5',
    '0x724a848e4e7388d39e9c20ebcfd79a8b7d928bc6',
    '0x32474b38a7dba802cd0b0c4f86d068ae7bb0fc8f',
    '0x2745014c8598fba290b9f42f4be92c56c2e174b8',
    '0xedf703593c0e956d88b6423ad4c3c3ebc4fc212d',
    '0x1677f358043f92c827869d40932b11dee6c46789',
    '0xffe5fbd2a733bc252cd9704933ac8fbf815b0cdc',
    '0x96a0ebf7fe5276da52fce5fa4153a4c1e6d9d11c',
    '0x892097f494fdac99451d9a5b5de04d48c337915e',
    '0xaf007512796d375122a0667c85750cfea06d5248',
    '0x132dfe68815ad1ec82e7deddf3e9fb7674d3cf45',
    '0x4e9fd979ba9955de388332dce3fa1b8886e37f43',
    '0x8cfa4b450e90f102d7467fcbbbb464bedab62bf2',
    '0x21d5efb01981e93dc3accfb99b0bcb7bbf3524ef',
    '0x53fbd9b269933fa7ad2409ad51e3f87493fea414',
    '0xd45c77039319bc6e7eff36e01da94bffba2a3745',
    '0x739e7a05d7cc585fc08b217b85cf6c934aa31bb3',
    '0x3b3d24598b4f6fc084693a7ebc504963247730cc',
    '0x5cc84a069564a73d27d99e1ab7ec4379e2d83056',
    '0xb10e7ebe2a65f3dfaff04c91708391eebea70fe7',
    '0xabb8bc7859a74908d2213d4cb045a47e57b0043c',
    '0xdf35878ea8a2d37afa96e4d5cc2dc0426d43b764',
    '0x55e5e34dde7de8c7ab3d8a598a4c9f1a6df96806',
    '0xe942d1f42fc166f03683aa01f7d0ce50f3dd2a88',
    '0x4e796d9e1dfc4489a93c0a41c4a3599f361b0705',
    '0x99f14c610f3268d17fd0c7b5e7191c6ca0fd9dc5',
    '0xe55904e5155a982d58ba199ae5c001712f5517da',
    '0x33fd0b79948dd118c9862017794ee5b0b81fae3f',
    '0xa622de517e49ab3118e7fb5d9c2f1b6e4919ec41',
    '0x9fe447101eea98b371707459f7efa3914b6cbe7e',
    '0x6f028daf4cb8080fb76ce438915172d1580bde1a',
    '0x295e05272ff315c90df1d1c3432a6476824b5e2d',
    '0xffff75112748399aa90f7919208bf9d0c1e25d71',
    '0xe6e8dbf284ecf49b32c417c5dd9b9cbce27dceb2',
    '0xd264afb65dc268d577ff4b9d7bb81b96a87d629a',
    '0x3485380f29773ebe49bf9cda40d2716364ba5e1f',
    '0x5d1c092a4ec9788d8f5b80deba996bf524c70e97',
    '0xe6a1983ee5693925039e7c8931c921bc432a129e',
    '0xef9ecc42d039526c2eb007a058d708b8d25de7b3',
    '0xea5c4980b70f3432ef6f78000e4ef145167b4580',
    '0x9238ccfc5c128bfca30e6d44db263f256b1f725d',
    '0x05b728fd472f6530dea5b3fb038b4e2fe39d04d0',
    '0x665c43215974de665679c8e34205eb055578eb2b',
    '0xb023d8b5a2d52eeab77bbf2f4f15dc8f1066db41',
    '0x35f74a3e89d72b61a8300a85d393f31605866172',
    '0x12907462e036b6dd1b721970f78cd823b2f46254',
    '0x308b886a63837bfdcd15c35ed1c9742d6bda974f',
    '0x750c2ca72797bd681b5f64501707b7f8eb327c2e',
    '0x0ac4603ceb9029077b82cb96bdcaae8dc1e8414a',
    '0xb6a5293a00f9e0002a3b6ea77a391aa41aedf00f',
    '0x1fd999c388805f8757966f5c3dea0d74eff53dd4',
    '0x7a2a777642629c2bfc7b6a040a2f7aea307b43be',
    '0x68e8f77eea10146bdfcff5ea3961129a82be28ba',
    '0xfb371ac675f043b48173e2cf74e979ec6d7bee22',
    '0xeeccb56425649b309eec35ce90f6f91e8b06b099',
    '0x8a2d0024b539ff93e361dfed2144c3114384e030',
    '0xc83c036517cf50c27cc6c5a96cd46745b4e9134d',
    '0x09371eda619f6b2d6d2f50f092b3dd99104f3713',
    '0x988b2a936bd84b2c78a0ec234821a87aa64e6aa6',
    '0x2c61eda9b67a82d478fe7070e8d83fef743e0054',
    '0x688dab4b3923cd7a91c45bc77b1fa9e59f2ac3d3',
    '0xa130941ea504d6efbe0e0259f9b3ffbc8a29f2aa',
    '0x114cb77f20b5a83dca01df67f9f65da91e03a174',
]
function App() {
    const dispatch = useDispatch()
    const blockchain = useSelector((state) => state.blockchain)
    const data = useSelector((state) => state.data)
    const [claimingNft, setClaimingNft] = useState(false)
    const [feedback, setFeedback] = useState(`TFL`)
    const [mintAmount, setMintAmount] = useState(3)
    const [maxMintAmount, setMaxMintAmount] = useState(5)
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: '',
        SCAN_LINK: '',
        NETWORK: {
            NAME: '',
            SYMBOL: '',
            ID: 0,
        },
        NFT_NAME: '',
        SYMBOL: '',
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: '',
        MARKETPLACE_LINK: '',
        SHOW_BACKGROUND: false,
    })

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST
        let gasLimit = CONFIG.GAS_LIMIT
        let totalCostWei = String(cost * mintAmount)
        let totalGasLimit = String(gasLimit * mintAmount)
        console.log('Cost: ', totalCostWei)
        console.log('Gas limit: ', totalGasLimit)
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
        setClaimingNft(true)
        blockchain.smartContract.methods
            .mint(blockchain.account, mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once('error', (err) => {
                console.log(err)
                setFeedback('3 PER WALLET | 5 PER WL WALLET')
                setClaimingNft(false)
            })
            .then((receipt) => {
                console.log(receipt)
                setFeedback(
                    `Congrats! You have claimed your ${CONFIG.NFT_NAME}`
                )
                setClaimingNft(false)
                dispatch(fetchData(blockchain.account))
            })
    }

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1
        if (newMintAmount < 1) {
            newMintAmount = 1
        }
        setMintAmount(newMintAmount)
    }

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1
        if (newMintAmount > maxMintAmount) {
            newMintAmount = maxMintAmount
        }
        setMintAmount(newMintAmount)
    }

    const getData = () => {
        if (blockchain.account !== '' && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account))
        }
    }

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        const config = await configResponse.json()
        SET_CONFIG(config)
    }

    useEffect(() => {
        getConfig()
        // console.log(CONFIG.CONTRACT_ADDRESS)
    }, [])

    useEffect(() => {
        getData()
        if (blockchain.account) {
            if (!whitelisted_acc.includes(blockchain.account)) {
                setMaxMintAmount(3)
                setMintAmount(3)
                console.log('no')
            } else {
                setMaxMintAmount(5)
                setMintAmount(5)
                console.log('yes')
            }
        }
    }, [blockchain.account])

    return (
        <s.Screen>
            <s.Container
                flex={1}
                ai={'center'}
                style={{ padding: 24, backgroundColor: 'var(--primary)' }}
                image={CONFIG.SHOW_BACKGROUND ? '/config/images/bg.gif' : null}
            >
                <a href={CONFIG.MARKETPLACE_LINK}>
                    <StyledLogo alt={'logo'} src={'/config/images/logo.png'} />
                </a>
                <s.SpacerSmall />
                <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
                    <s.Container flex={1} jc={'center'} ai={'center'}>
                        <StyledImg
                            alt={'example'}
                            src={'/config/images/example.gif'}
                        />
                    </s.Container>
                    <s.SpacerLarge />
                    <s.Container
                        flex={2}
                        jc={'center'}
                        ai={'center'}
                        style={{
                            backgroundColor: 'var(--accent)',
                            padding: 24,
                            borderRadius: 24,
                            border: '4px dashed var(--secondary)',
                            boxShadow: '0px 5px 11px 2px rgba(0,0,0,0.7)',
                        }}
                    >
                        <s.TextTitle
                            style={{
                                textAlign: 'center',
                                fontSize: 50,
                                fontWeight: 'bold',
                                color: 'var(--accent-text)',
                            }}
                        >
                            {data.totalSupply === 0 ? '???' : data.totalSupply}/
                            {CONFIG.MAX_SUPPLY}
                        </s.TextTitle>
                        <s.TextDescription
                            style={{
                                textAlign: 'center',
                                color: 'var(--primary-text)',
                            }}
                        >
                            <StyledLink
                                target={'_blank'}
                                href={CONFIG.SCAN_LINK}
                            >
                                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                            </StyledLink>
                        </s.TextDescription>
                        <span
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <StyledButton
                                style={{
                                    margin: '5px',
                                }}
                                onClick={(e) => {
                                    window.open(
                                        CONFIG.MARKETPLACE_LINK,
                                        '_blank'
                                    )
                                }}
                            >
                                {CONFIG.MARKETPLACE}
                            </StyledButton>
                        </span>
                        <s.SpacerSmall />
                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                            <>
                                <s.TextTitle
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--accent-text)',
                                    }}
                                >
                                    The sale has ended.
                                </s.TextTitle>
                                <s.TextDescription
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--accent-text)',
                                    }}
                                >
                                    You can still find {CONFIG.NFT_NAME} on
                                </s.TextDescription>
                                <s.SpacerSmall />
                                <StyledLink
                                    target={'_blank'}
                                    href={CONFIG.MARKETPLACE_LINK}
                                >
                                    {CONFIG.MARKETPLACE}
                                </StyledLink>
                            </>
                        ) : (
                            <>
                                <s.TextTitle
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--accent-text)',
                                    }}
                                >
                                    WELCOME LOSER | HERE'S A FREE GIFT AFTER
                                    GETTING REKT!
                                </s.TextTitle>
                                <s.SpacerXSmall />
                                <s.TextDescription
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--accent-text)',
                                    }}
                                >
                                    WHITELISTED USERS CLAIM UPTO 5 & NON-WL UPTO
                                    3 PER WALLET!
                                </s.TextDescription>
                                <s.SpacerSmall />
                                {blockchain.account === '' ||
                                blockchain.smartContract === null ? (
                                    <s.Container ai={'center'} jc={'center'}>
                                        <s.TextDescription
                                            style={{
                                                textAlign: 'center',
                                                color: 'var(--accent-text)',
                                            }}
                                        >
                                            Connect to the {CONFIG.NETWORK.NAME}{' '}
                                            network
                                        </s.TextDescription>
                                        <s.SpacerSmall />
                                        <StyledButton
                                            onClick={(e) => {
                                                e.preventDefault()
                                                dispatch(connect())
                                                getData()
                                            }}
                                        >
                                            CONNECT
                                        </StyledButton>
                                        {blockchain.errorMsg !== '' ? (
                                            <>
                                                <s.SpacerSmall />
                                                <s.TextDescription
                                                    style={{
                                                        textAlign: 'center',
                                                        color: 'var(--accent-text)',
                                                    }}
                                                >
                                                    {blockchain.errorMsg}
                                                </s.TextDescription>
                                            </>
                                        ) : null}
                                    </s.Container>
                                ) : (
                                    <>
                                        <s.TextDescription
                                            style={{
                                                textAlign: 'center',
                                                color: 'var(--accent-text)',
                                            }}
                                        >
                                            {feedback}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <s.Container
                                            ai={'center'}
                                            jc={'center'}
                                            fd={'row'}
                                        >
                                            <StyledRoundButton
                                                style={{ lineHeight: 0.4 }}
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    decrementMintAmount()
                                                }}
                                            >
                                                -
                                            </StyledRoundButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                                style={{
                                                    textAlign: 'center',
                                                    color: 'var(--accent-text)',
                                                }}
                                            >
                                                {mintAmount}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledRoundButton
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    incrementMintAmount()
                                                }}
                                            >
                                                +
                                            </StyledRoundButton>
                                        </s.Container>
                                        <s.SpacerSmall />
                                        <s.Container
                                            ai={'center'}
                                            jc={'center'}
                                            fd={'row'}
                                        >
                                            <StyledButton
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    claimNFTs()
                                                    getData()
                                                }}
                                            >
                                                {claimingNft
                                                    ? 'BUSY'
                                                    : 'CLAIM YOUR TFL'}
                                            </StyledButton>
                                        </s.Container>
                                    </>
                                )}
                            </>
                        )}
                        <s.SpacerMedium />
                    </s.Container>
                    <s.SpacerLarge />
                    <s.Container flex={1} jc={'center'} ai={'center'}>
                        <StyledImg
                            alt={'example'}
                            src={'/config/images/example.gif'}
                            style={{ transform: 'scaleX(-1)' }}
                        />
                    </s.Container>
                </ResponsiveWrapper>
                <s.SpacerMedium />
                <s.Container
                    jc={'center'}
                    ai={'center'}
                    style={{ width: '70%' }}
                >
                    <s.TextDescription
                        style={{
                            textAlign: 'center',
                            color: 'var(--primary-text)',
                        }}
                    >
                        Please make sure you are connected to the right network
                        ({CONFIG.NETWORK.NAME} Mainnet) and the correct address.
                        Please note: Once you make the purchase, you cannot undo
                        this action.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.TextDescription
                        style={{
                            textAlign: 'center',
                            color: 'var(--primary-text)',
                        }}
                    >
                        We have set the gas limit to {CONFIG.GAS_LIMIT} for the
                        contract to successfully mint your NFT. We recommend
                        that you don't lower the gas limit.
                    </s.TextDescription>
                </s.Container>
            </s.Container>
        </s.Screen>
    )
}

export default App
